import { Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { drawerwidth } from '../theme/theme'
import { Button, Grid, Tooltip } from '@mui/material';

const openedMixin = (theme) => ({
  width: drawerwidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerwidth,
    width: `calc(100% - ${drawerwidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerwidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniDrawer({ menuNavigationItems, open, setOpen }) {
  const theme = useTheme();
  const navigate = useNavigate()
  localStorage.removeItem('programmes_filter')
  localStorage.removeItem('programmes_search')
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem(`${process.env.REACT_APP_GCP_PROJECT}_access`)
    localStorage.removeItem(`${process.env.REACT_APP_GCP_PROJECT}_refresh`)
    localStorage.removeItem('role')
    localStorage.removeItem('superUser')
    navigate('/')
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} drawerwidth={drawerwidth}>
        <Toolbar>
          <Grid
            container
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <Grid
              xs={8}
              item={true}
              container
              flexDirection='row'
              alignItems='center'
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                Payaway
              </Typography>
            </Grid>
            <Button
              variant='contained'
              color='secondary'
              onClick={handleLogout}
            >Log Out</Button>

          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <List>
          {
            menuNavigationItems.map(({ sectionName, headers }) => (
              <Fragment key={sectionName}>
                {headers.map(({ pageName, pageLink, navIcon, params, hidden }) => {
                  if (hidden) return
                  const currentUrl = window.location.href;
                  const url = new URL(currentUrl);
                  const pathname = url.pathname;
                  const currentPage = pathname.split('/').filter(Boolean).pop();

                  console.log(currentPage);
                  const isSameName = currentPage.toLowerCase() === pageLink.toLowerCase();
                  return (
                    <ListItem key={pageName} disablePadding sx={{ display: 'block', marginTop: '0.5rem' ,backgroundColor: isSameName ? '#DEDEDF' : '#FEFEFF',}}>
                      <Tooltip title={!open && pageName} disableInteractive placement="right">
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                          }}
                          to={`/${pageLink}${params ? params : ''}`}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : 'auto',
                              justifyContent: 'center',
                            }}
                          >
                            {navIcon}
                          </ListItemIcon>
                          <ListItemText primary={pageName} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                      </Tooltip>
                    </ListItem>
                  )
                }
                )}
                <Divider sx={{ marginTop: '0.5rem' }}/>
              </Fragment >
            ))
          }
        </List>
      </Drawer>
      <DrawerHeader />
    </Box>
  );
}
